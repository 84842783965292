import React, { useState } from 'react';

import { cn } from '~/utils/cn';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

const Tooltip = ({ text, children, position = 'top' }: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      <div
        className={cn(
          'absolute z-50 w-24 rounded-lg bg-black px-2 py-1 text-center text-sm text-white shadow-lg max-md:hidden',
          'opacity-0 transition-all translate-y-1 duration-300 pointer-events-none',
          'after:absolute after:size-2 after:bg-black ',
          isVisible && 'opacity-100 translate-y-0 pointer-events-auto',
          position === 'top' &&
            'bottom-full left-1/2 -translate-x-1/2 mb-3 after:-bottom-1 after:rotate-45 after:inset-x-1/2 after:-translate-x-1/2',
          position === 'bottom' &&
            'top-full left-1/2 -translate-x-1/2 mt-3 after:-top-1 after:rotate-45 after:inset-x-1/2 after:-translate-x-1/2',
          position === 'left' &&
            'right-full top-1/2 -translate-y-1/2 mr-3 after:-right-1 after:rotate-45 after:inset-y-1/2 after:-translate-y-1/2',
          position === 'right' &&
            'left-full top-1/2 -translate-y-1/2 ml-3 after:-left-1 after:rotate-45 after:inset-y-1/2 after:-translate-y-1/2'
        )}
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
